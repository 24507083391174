import * as React from "react";
const SvgPolypedestra = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "polypedestra.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 13.077158, "inkscape:cy": 10.967939, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("path", { "sodipodi:type": "star", fill: "currentcolor", style: {
  opacity: 0.95,
  fillOpacity: 0.57429,
  stroke: "#000000",
  strokeWidth: 0.1,
  strokeDasharray: "none"
}, id: "path31", "inkscape:flatsided": "true", "sodipodi:sides": 5, "sodipodi:cx": 38.092529, "sodipodi:cy": 35.36755, "sodipodi:r1": 6.0642557, "sodipodi:r2": 4.906086, "sodipodi:arg1": -1.5707963, "sodipodi:arg2": -0.9424778, "inkscape:rounded": 0, "inkscape:randomized": 0, d: "m 38.092529,29.303294 5.76745,4.190298 -2.20297,6.780044 -7.12896,0 -2.20297,-6.780044 z", "inkscape:transform-center-y": -0.06676627, transform: "matrix(0.58204069,0,0,0.19044812,-13.759017,6.6562921)", "inkscape:export-filename": "path31.png", "inkscape:export-xdpi": 300, "inkscape:export-ydpi": 300 }), /* @__PURE__ */ React.createElement("g", { id: "g1" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9-4-8-05-9-4-7", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 11.475087,13.18155 c -0.0023,8.4e-5 -0.0036,-4.82e-4 -0.0042,-0.0016 l -0.582582,-0.97031 0.679239,-0.407815 0.58256,0.970344 c 0.0081,0.0134 -0.137578,0.115575 -0.325057,0.228136 -0.171726,0.103109 -0.321915,0.180817 -0.349935,0.181078 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2-7-1-5-3-2-0", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 10.892671,12.211468 c -2.22e-4,0.0022 -0.0036,-4.82e-4 -0.0042,-0.0016 L 6.1760613,4.3608225 6.8552763,3.953033 11.567716,11.802021 c 0.0081,0.01327 -0.137457,0.115533 -0.325049,0.228171 -0.171719,0.103092 -0.347319,0.153147 -0.349952,0.181088 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2-7-3-29-6-2-8", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 6.1802333,4.3624535 c 0,0 -0.0037,-2.246e-4 -0.0042,-0.00163 L 5.5934523,3.390525 c -0.008,-0.013757 0.137459,-0.1155342 0.325048,-0.2281607 0.18757,-0.1126168 0.346145,-0.1930496 0.354191,-0.1796441 l 0.582562,0.9703426 c 0.008,0.013757 -0.137599,0.1155899 -0.32506,0.2281377 -0.171704,0.1030849 -0.351215,0.2141149 -0.349925,0.1812598 z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9-4-8-3-0-4-6", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 10.154849,14.360874 c -0.0022,-5.93e-4 -0.0033,-0.0015 -0.0036,-0.0028 L 9.8826083,13.25865 10.65223,13.070601 l 0.268611,1.09945 c 0.0036,0.01517 -0.165663,0.06958 -0.378089,0.121483 -0.194578,0.04755 -0.361056,0.07722 -0.387893,0.06917 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2-7-1-8-5-1-2", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 9.8744793,13.21333 c -8.43e-4,0.002 -0.0033,-0.0015 -0.0036,-0.0028 l -2.29298,-9.384913 0.769591,-0.1880326 2.2930137,9.3848976 c 0.0038,0.01507 -0.165533,0.06957 -0.378089,0.121517 -0.194567,0.04753 -0.3770407,0.04329 -0.3879117,0.06917 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2-7-3-040-0-2-4", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.5814733,3.8284084 c 0,0 -0.0035,-0.00134 -0.0036,-0.00278 L 7.3092853,2.7261836 c -0.0035,-0.015475 0.165535,-0.069571 0.378085,-0.1215074 0.212529,-0.051926 0.387823,-0.081715 0.391531,-0.066528 l 0.268613,1.0994504 c 0.0035,0.015475 -0.165691,0.069581 -0.378091,0.1214824 -0.194549,0.04753 -0.398912,0.1003346 -0.387939,0.069341 z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9-4-8-0-2-8-7", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 8.0742003,12.212526 c -0.0021,-9.29e-4 -0.0031,-0.002 -0.0032,-0.0033 l -0.10043,-1.127303 0.789137,-0.0703 0.100397,1.127326 c 0.0013,0.01554 -0.174237,0.04391 -0.392048,0.06331 -0.199513,0.01778 -0.368559,0.02211 -0.39388,0.01011 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2-7-1-4-9-9-9", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.9715203,11.059976 c -0.0011,0.0019 -0.0031,-0.002 -0.0032,-0.0033 l -0.580028,-6.5121519 0.789105,-0.070291 0.580062,6.5121409 c 0.0015,0.01548 -0.174108,0.04392 -0.392054,0.06334 -0.199498,0.01776 -0.379997,-0.01429 -0.393899,0.0101 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2-7-3-2-4-2-3", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.3914663,4.5478125 c 0,0 -0.0033,-0.00182 -0.0032,-0.00326 L 7.2878753,3.4172282 c -0.0011,-0.015821 0.174109,-0.04392 0.392049,-0.063331 0.217917,-0.019421 0.395697,-0.022531 0.397082,-0.00691 l 0.100397,1.1273271 c 0.0011,0.015811 -0.174261,0.043901 -0.39205,0.063303 -0.199481,0.01776 -0.409457,0.039264 -0.39395,0.010272 z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9-4-8-9-3-8-9", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 6.0307003,14.13754 c -0.0015,-0.0017 -0.0019,-0.0032 -0.0014,-0.0043 l 0.376098,-1.06745 0.747236,0.263281 -0.376136,1.067456 c -0.0053,0.0147 -0.176756,-0.03229 -0.383002,-0.104974 -0.18892,-0.06656 -0.344541,-0.132723 -0.362607,-0.154142 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2-7-1-1-5-8-2", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 6.4069193,13.069828 c -0.0018,0.0013 -0.0019,-0.0032 -0.0014,-0.0043 L 9.6325813,3.9069375 10.379783,4.170217 7.1527583,13.328792 c -0.00513,0.01469 -0.176683,-0.03224 -0.383061,-0.104946 -0.188908,-0.06655 -0.339431,-0.169933 -0.362629,-0.154136 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2-7-3-0-1-6-8", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 9.6339633,3.9112354 c 0,0 -0.0022,-0.00307 -0.0014,-0.00432 L 10.008675,2.8394533 c 0.0056,-0.014851 0.176644,0.032237 0.383012,0.1049522 0.206346,0.072701 0.369411,0.1435893 0.364215,0.158335 L 10.379765,4.1701978 C 10.374165,4.1850488 10.202986,4.1378838 9.9967617,4.0652226 9.8078753,3.9986656 9.6078863,3.9311555 9.6340193,3.9112018 Z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9-4-8-7-3-30", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 4.7836313,12.781252 c -0.0012,-0.0019 -0.0013,-0.0034 -6.26e-4,-0.0045 l 0.568774,-0.978468 0.684946,0.398156 -0.568814,0.978467 c -0.00787,0.0135 -0.167616,-0.0647 -0.356667,-0.174596 -0.173175,-0.10066 -0.313707,-0.194714 -0.327456,-0.219131 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2-7-1-43-8-1", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 5.3522883,11.803041 c -0.0021,7.37e-4 -0.0013,-0.0034 -6.26e-4,-0.0045 L 9.6556203,4.3947202 10.340531,4.7928703 6.0366093,12.196692 c -0.0077,0.01349 -0.167519,-0.06465 -0.356693,-0.174608 -0.173155,-0.100626 -0.30086,-0.228027 -0.32747,-0.219104 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2-7-3-1-3-7", style: {
  fill: "#ff33c1",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.134905,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 9.6562313,4.3992062 c 0,0 -0.0016,-0.00336 -6.26e-4,-0.00451 L 10.224394,3.4162193 c 0.0082,-0.013555 0.16752,0.064646 0.356684,0.1746128 0.189145,0.1099499 0.33611,0.210033 0.32825,0.2235488 L 10.340517,4.7928473 C 10.332217,4.8064023 10.172883,4.7280953 9.9838447,4.6182134 9.8107063,4.5175582 9.6268373,4.413902 9.6562373,4.3991775 Z", "sodipodi:nodetypes": "cccsccscc" }))));
export default SvgPolypedestra;
