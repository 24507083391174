import * as React from "react";
const SvgTripod = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "tripod.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 13.077158, "inkscape:cy": 10.967939, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-8", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.158857,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.9695331,13.343395 c -0.00201,-0.0015 -0.00364,-0.0025 -0.00345,-0.004 l -1.53e-5,-1.332716 0.9329205,-10e-6 -1.91e-5,1.332733 c -3.304e-4,0.01858 -0.2089439,0.0333 -0.4664469,0.0333 -0.2358643,-1e-6 -0.4345916,-0.01258 -0.4630366,-0.02931 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-8", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.158857,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.9695379,12.010696 c 7.01e-4,-0.0026 -0.00364,-0.0025 -0.00345,-0.004 l 3.06e-5,-8.0944719 0.9328842,3.8e-6 1.72e-5,8.0944601 c -3.064e-4,0.01862 -0.2087963,0.03332 -0.4664631,0.03333 -0.2358432,-4e-6 -0.4686879,0.0033 -0.4630644,-0.0293 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-9", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.158857,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 7.969537,3.9162071 c 0,0 -0.00364,-0.00249 -0.00345,-0.00402 L 7.9660702,2.579463 C 7.9663824,2.560849 8.1748751,2.546138 8.4325247,2.54614 c 0.2576246,-2.7e-6 0.4664698,0.014913 0.4664698,0.033319 l -1.91e-5,1.3327334 c -3.304e-4,0.018579 -0.2089783,0.033289 -0.4664507,0.033289 -0.2358298,0 -0.4842071,0.00306 -0.4629877,-0.029276 z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-0", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 12.341634,14.389084 c -0.0023,-1.2e-4 -0.0037,-8.03e-4 -0.0044,-0.0019 l -0.64196,-1.11187 0.77833,-0.449383 0.641936,1.111905 c 0.0089,0.01534 -0.158277,0.128427 -0.373118,0.252466 -0.196778,0.11361 -0.36864,0.198835 -0.400423,0.198584 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-9", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 11.699539,13.276924 c -0.0017,-0.0019 -0.0037,-8.03e-4 -0.0044,-0.0019 L 6.6941202,4.6129397 7.4724252,4.1635913 12.473476,12.825627 c 0.0089,0.01529 -0.158156,0.128368 -0.373117,0.252487 -0.19677,0.113605 -0.379324,0.222431 -0.400444,0.198607 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-2", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 6.698492,4.614856 c 0,0 -0.0045,-1.589e-4 -0.00441,-0.00192 L 6.0521229,3.5010641 C 6.043408,3.4853773 6.2102771,3.3726746 6.4252351,3.2485688 6.6401664,3.1244754 6.8215856,3.0363229 6.8304537,3.0516831 l 0.641936,1.1119043 C 7.4811046,4.1792455 7.3140832,4.292021 7.0992746,4.4160406 6.902522,4.5296359 6.6967203,4.6518828 6.698832,4.6146473 Z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5-9", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 3.818248,13.94295 c -0.00134,-0.0022 -0.00153,-0.0038 -7.355e-4,-0.0051 l 0.6419246,-1.111864 0.7783423,0.449365 -0.6419696,1.111887 c -0.00891,0.01529 -0.1903619,-0.07287 -0.4051917,-0.196904 -0.196787,-0.113607 -0.3565224,-0.21983 -0.3722006,-0.247483 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3-2", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 4.4603613,12.830796 c 8.446e-4,-0.0024 -0.00153,-0.0038 -7.355e-4,-0.0051 L 9.460686,4.1636784 10.23899,4.6130336 5.237971,13.275088 C 5.229261,13.290408 5.0477191,13.202318 4.8327505,13.078212 4.6359845,12.964604 4.4504684,12.860903 4.4605327,12.830718 Z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8-2", style: {
  fill: "#b733ff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.153037,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 9.461406,4.1687263 c 0,0 -0.0018,-0.00383 -7.36e-4,-0.00508 l 0.64194,-1.1118995 c 0.0093,-0.0154 0.190255,0.072774 0.405211,0.19688 0.214937,0.1240904 0.381992,0.2371283 0.373122,0.2524876 l -0.641969,1.111888 C 10.229684,4.6284062 10.04859,4.5401177 9.83378,4.4161 9.637032,4.3025009 9.428119,4.185503 9.461406,4.1687263 Z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { "sodipodi:type": "star", fill: "currentcolor", style: {
  opacity: 0.95,
  fillOpacity: 0.57429,
  stroke: "#000000",
  strokeWidth: 0.1,
  strokeDasharray: "none"
}, id: "path4", "inkscape:flatsided": "true", "sodipodi:sides": 3, "sodipodi:cx": 36.34581, "sodipodi:cy": 21.348686, "sodipodi:r1": 1.6110823, "sodipodi:r2": 1.3033929, "sodipodi:arg1": -1.5707963, "sodipodi:arg2": -0.52359875, "inkscape:rounded": 0, "inkscape:randomized": 0, d: "m 36.34581,19.737604 1.395238,2.416623 -2.790476,0 z", "inkscape:transform-center-y": -0.064837753, transform: "matrix(2.7191152,0,0,0.42144687,-90.363135,5.0884558)", "inkscape:transform-center-x": 23043565e-13 })));
export default SvgTripod;
