import * as React from "react";
const SvgPole = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "pole.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 13.077158, "inkscape:cy": 10.967939, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g1", transform: "translate(-13.866971,1.6729168)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 25.126959,12.701937 c -0.003,1e-6 -0.0049,-7.34e-4 -0.0057,-0.002 l -0.769914,-1.333484 0.933466,-0.538954 0.769886,1.333527 c 0.01062,0.0184 -0.189831,0.15402 -0.447489,0.302779 -0.236002,0.136256 -0.442116,0.238466 -0.48024,0.238168 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 24.357079,11.368451 c -0.003,10e-7 -0.0049,-7.35e-4 -0.0057,-0.002 l -4.968816,-8.606304 0.933431,-0.538908 4.968855,8.606264 c 0.0107,0.01837 -0.189669,0.153961 -0.447481,0.302809 -0.235988,0.136248 -0.442124,0.238482 -0.480261,0.238192 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 19.388222,2.762153 c 0,0 -0.0049,-7.34e-4 -0.0057,-0.002 L 18.612604,1.426662 C 18.601924,1.408282 18.80228,1.272697 19.060084,1.123853 19.317856,0.975029 19.535443,0.8693 19.546073,0.887712 l 0.769886,1.333528 c 0.01062,0.0184 -0.189876,0.154034 -0.447494,0.30277 -0.235968,0.136237 -0.482815,0.282795 -0.480249,0.238197 z", "sodipodi:nodetypes": "cccsccscc" }))));
export default SvgPole;
