// add sjorcraftcanvas with a right panel that is collapsable
import React, { useEffect, useRef } from "react";
import { SjorcraftCanvas } from "./SjorcraftCanvas";
import Toolbar from "./Toolbar";
import { Viewer } from "../../../sjorcraft3d/src/viewer";
import {
  RendererContext,
  RendererContextType,
} from "../contexts/rendererContext";
import EditorSidebar from "./EditorSidebar";
import { useDeviceSize } from "../hooks/useDeviceSize";
import { Box, Stack } from "@mui/material";
import Disclaimer from "./buildingBlocks/Disclaimer";

export type SjorcraftEditorProps = {
  parameterObject: {
    isLightMode: boolean;
    toggleLightmode: () => void;
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    floorColor: string;
    setFloorColor: (color: string) => void;
    isGrassTexture: boolean;
    toggleFloorTexture: () => void;
    exportLashings: boolean;
    toggleExportLashings: () => void;
    openDisclaimer?: boolean;
    toggleDisclaimer?: (open: boolean) => void;
  };
};
const SjorcraftEditor = ({ parameterObject }: SjorcraftEditorProps) => {
  const [rendererContext, setRendererContext] =
    React.useState<RendererContextType>({});
  const viewerRef = useRef(null);

  useEffect(() => {
    if (viewerRef.current) return;
    const element = document.getElementById("render_area");
    if (!element) return;

    const viewer = new Viewer(element);
    viewerRef.current = viewer;
    setRendererContext({ viewer });
  }, []);

  const { smDevice } = useDeviceSize();

  return (
    <Box
      sx={{
        margin: "0",
        padding: "0",
        height: "100%",
        width: "100%",
      }}
    >
      <RendererContext.Provider value={rendererContext}>
        {!smDevice && (
          <Toolbar
            isLightMode={parameterObject.isLightMode}
            toggleLightMode={parameterObject.toggleLightmode}
            isSidebarOpen={parameterObject.isSidebarOpen}
            toggleSidebar={parameterObject.toggleSidebar}
          />
        )}
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "0rem",
          }}
        >
          <Disclaimer
            openDisclaimer={parameterObject.openDisclaimer}
            toggleDisclaimer={parameterObject.toggleDisclaimer}
          />
          <Stack
            direction={"row"}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "0rem",
              flexWrap: "nowrap",
              justifyContent: "normal",
            }}
          >
            <SjorcraftCanvas parameterObject={parameterObject} />
            {parameterObject.isSidebarOpen && !smDevice ? (
              <EditorSidebar parameterObject={parameterObject} />
            ) : null}
          </Stack>
        </Box>
      </RendererContext.Provider>
    </Box>
  );
};

export default SjorcraftEditor;
