import * as THREE from "three";
import { Pole } from "./objects/pole";
import { Viewer } from "./viewer";
import { Lashing } from "./objects/lashings/lashing";
import { BipodLashing } from "./objects/lashings/bipodLashing";

export class InputHandler {
  viewer: Viewer;
  cursor: { x: number; y: number };
  mouseHasMoved: boolean;
  mouseDown: boolean;
  hoveredHandle: THREE.Mesh | undefined = undefined;
  ctrlDown: boolean;

  constructor(viewer: Viewer) {
    this.viewer = viewer;
    const domElement = this.viewer.domElement;

    this.mouseHasMoved = false;

    this.cursor = { x: 0, y: 0 };
    window.addEventListener("keydown", this.onKeyDown.bind(this));
    window.addEventListener("keyup", this.onKeyUp.bind(this));
    domElement.addEventListener("mousedown", this.onMouseDown.bind(this));
    domElement.addEventListener("mouseup", this.onMouseUp.bind(this));
    domElement.addEventListener("mousemove", this.onMouseMove.bind(this));
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "Delete":
      case "Backspace":
        if (this.ctrlDown) {
          event.preventDefault();
          this.viewer.inventory.removeAll();
          this.viewer.saveTool.clearLocalStorage();
        } else {
          this.viewer.selectionTool.deleteSelectedPoles();
        }
        break;
      case "Control":
        this.ctrlDown = true;
        break;
      case "ArrowUp":
        if (this.viewer.polypedestraTool.active) {
          this.viewer.polypedestraTool.arrowUp();
        }
        break;
      case "ArrowDown":
        if (this.viewer.polypedestraTool.active) {
          this.viewer.polypedestraTool.arrowDown();
        }
        break;
      case "a":
        event.preventDefault();
        if (this.viewer.selectionTool.active && this.ctrlDown) {
          this.viewer.selectionTool.selectAll();
        }
        break;
      default:
        console.log("down", event.key);
        break;
    }
  }

  onKeyUp(event: any) {
    switch (event.key) {
      case "Control":
        this.ctrlDown = false;
        break;
      default:
        console.log("up", event.key);
    }
  }

  onMouseDown(event: any) {
    this.mouseHasMoved = false;
    this.mouseDown = true;
  }

  onMouseUp(event: any) {
    this.mouseDown = false;
    if (this.mouseHasMoved) {
      if (this.viewer.transformationTool.active) {
        this.viewer.transformationTool.drop();
      }
    } else {
      if (this.viewer.poleTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.poleTool.leftClick();
        } else if (event.button === THREE.MOUSE.RIGHT) {
          this.viewer.poleTool.rightClick();
        }
      } else if (this.viewer.selectionTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.selectionTool.leftClick(this.ctrlDown);
        }
      } else if (this.viewer.destructionTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.destructionTool.leftClick();
        }
      } else if (this.viewer.bipodTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.bipodTool.leftClick();
        } else {
          this.viewer.bipodTool.rightClick();
        }
      } else if (this.viewer.tripodTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.tripodTool.leftClick();
        } else {
          this.viewer.tripodTool.rightClick();
        }
      } else if (this.viewer.polypedestraTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.polypedestraTool.leftClick();
        } else {
          this.viewer.polypedestraTool.rightClick();
        }
      } else if (this.viewer.lashingTool.active) {
        if (event.button === THREE.MOUSE.LEFT) {
          this.viewer.lashingTool.leftClick();
        }
      }
    }
  }

  onMouseMove(event: any) {
    this.mouseHasMoved = true;
    const rect = this.viewer.renderer.domElement.getBoundingClientRect();
    this.cursor.x = (event.clientX - rect.left) / this.viewer.sizes.width - 0.5;
    this.cursor.y =
      -(event.clientY - rect.top) / this.viewer.sizes.height + 0.5;
    const groundPosition = this.getGroundPosition();

    const poleIntersect = this.getPoleIntersect();
    const hoveredPole = poleIntersect?.object.parent as Pole;

    const objectIntersect = this.getObjectIntersect();
    const hoveredObject = objectIntersect?.object.parent as
      | Pole
      | Lashing
      | BipodLashing;

    if (this.viewer.poleTool.active) {
      if (poleIntersect?.normal) {
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.extractRotation(hoveredPole.matrix);

        const transformedNormal = poleIntersect.normal
          .clone()
          .applyMatrix4(rotationMatrix)
          .normalize();

        this.viewer.poleTool.drawPoleWhileHoveringOtherPole(
          poleIntersect.point,
          hoveredPole,
          transformedNormal
        );
      } else {
        this.viewer.poleTool.drawPoleWhileHoveringGound(groundPosition);
      }
    } else if (this.viewer.selectionTool.active) {
      this.viewer.selectionTool.setHoveredPole(hoveredPole);
      if (this.mouseDown) {
      } else {
        this.viewer.selectionTool.hoveredPole = hoveredPole;
      }
    } else if (this.viewer.destructionTool.active) {
      this.viewer.destructionTool.setHoveredObject(hoveredObject);
    } else if (this.viewer.bipodTool.active) {
      this.viewer.bipodTool.drawBipod(groundPosition);
    } else if (this.viewer.tripodTool.active) {
      this.viewer.tripodTool.drawTripod(groundPosition);
    } else if (this.viewer.polypedestraTool.active) {
      this.viewer.polypedestraTool.drawPolypedestra(groundPosition);
    } else if (this.viewer.lashingTool.active) {
      this.viewer.lashingTool.setHoveredPole(hoveredPole);
    } else if (this.viewer.transformationTool.active) {
      this.viewer.transformationTool.onMouseMove(this.cursor, this.mouseDown);
    }
  }

  getObjectIntersect() {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(
      new THREE.Vector2(this.cursor.x * 2, this.cursor.y * 2),
      this.viewer.camera
    );

    const intersects = raycaster.intersectObjects([
      ...this.viewer.inventory.poles.map((pole) => pole.mesh),
      ...this.viewer.inventory.lashings.map((lashing) => lashing.mesh),
      ...this.viewer.inventory.bipodLashings.map((lashing) => lashing.mesh),
    ]);

    if (intersects.length) {
      return intersects[0];
    } else {
      return undefined;
    }
  }

  getPoleIntersect() {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(
      new THREE.Vector2(this.cursor.x * 2, this.cursor.y * 2),
      this.viewer.camera
    );

    const intersects = raycaster.intersectObjects(
      this.viewer.inventory.poles.map((pole) => pole.mesh)
    );

    if (intersects.length) {
      return intersects[0];
    } else {
      return undefined;
    }
  }

  getGroundPosition() {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(
      new THREE.Vector2(this.cursor.x * 2, this.cursor.y * 2),
      this.viewer.camera
    );

    const intersect = raycaster.intersectObject(this.viewer.floor.mesh);
    if (intersect.length) {
      return intersect[0].point;
    }
    return new THREE.Vector3(0, 0, 0);
  }

  getPointOnLineClosestToCursor(
    lineOrigin: THREE.Vector3,
    lineDirection: THREE.Vector3
  ) {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(
      new THREE.Vector2(this.cursor.x * 2, this.cursor.y * 2),
      this.viewer.camera
    );
    const w0 = raycaster.ray.origin.clone().sub(lineOrigin);
    const a = lineDirection.dot(lineDirection);
    const b = lineDirection.dot(raycaster.ray.direction);
    const c = raycaster.ray.direction.dot(raycaster.ray.direction);
    const dDotW0 = lineDirection.dot(w0);
    const rayDirectionDotW0 = raycaster.ray.direction.dot(w0);

    const denom = a * c - b * b;
    const s = (b * rayDirectionDotW0 - c * dDotW0) / denom;
    const target = lineOrigin
      .clone()
      .sub(lineDirection.clone().multiplyScalar(s));

    return target;
  }

  deactiveTools() {
    this.viewer.selectionTool.deactivate();
    this.viewer.poleTool.deactivate();
    this.viewer.bipodTool.deactivate();
    this.viewer.tripodTool.deactivate();
    this.viewer.polypedestraTool.deactivate();
    this.viewer.destructionTool.deactivate();
    this.viewer.lashingTool.deactivate();
    this.viewer.transformationTool.deactivate();
  }

  onActivateTool(tool: string) {
    this.deactiveTools();
    switch (tool) {
      case "selectiontool":
        this.viewer.selectionTool.activate();
        break;
      case "poletool":
        this.viewer.poleTool.activate();
        break;
      case "bipodtool":
        this.viewer.bipodTool.activate();
        break;
      case "tripodtool":
        this.viewer.tripodTool.activate();
        break;
      case "polypedestratool":
        this.viewer.polypedestraTool.activate();
        break;
      case "lashingtool":
        this.viewer.lashingTool.activate();
        break;
      case "destructiontool":
        this.viewer.destructionTool.activate();
        break;
      case "transformationtool":
        this.viewer.transformationTool.activate();
        break;
      default:
        break;
    }
  }
}
