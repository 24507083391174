export const questions: { question: string; answer: string }[] = [
  {
    question: "What is Sjorcraft?",
    answer:
      "Sjorcraft is a web-based 3D editor for creating, sharing and exporting SJORconstructions.",
  },
  {
    question: "Can I export my construction from Sjorcraft?",
    answer:
      "Yes, you can export your construction from Sjorcraft as different files (e.g. .sjor, .jpg, .stl, .dae) different filetypes are on the way. You can find the export button in the top right corner of the editor.",
  },
  {
    question: "Is Sjorcraft free to use?",
    answer:
      'Yes, Sjorcraft is free to use. You can always support us by "buying us a coffee" on our website.',
  },
  {
    question: "Can I cancel drawing a pole?",
    answer:
      "Yes, you can cancel drawing a pole by rightclicking while drawing. This action is possible while using the pole-, bipod-, tripod- and polypedestratool.",
  },
  {
    question: "How does the polypedestratool work?",
    answer:
      "Click on the polypedestra icon in the toolbar. Then left-click three times in your drawing to set the middle point, the opening angle, and the height of the lashing. You can always go back to the previous step by right-clicking. You can change the number of poles in polypedestra with the up and down arrows on the keyboard.",
  },
];
