import * as React from "react";
const SvgBipod = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "bipod.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview1", pagecolor: "#505050", bordercolor: "#eeeeee", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#d1d1d1", "inkscape:zoom": 9.328125, "inkscape:cx": 18.921273, "inkscape:cy": 32.268007, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 12.992789, "inkscape:cy": 10.88357, "inkscape:window-width": 1920, "inkscape:window-height": 1043, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g1", transform: "translate(-12.376023,-2.0318579)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5", style: {
  fill: "#652bd5",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 24.922373,16.418088 c -0.0024,-1.25e-4 -0.0039,-8.38e-4 -0.0046,-0.002 l -0.670329,-1.161003 0.812733,-0.469246 0.670304,1.16104 c 0.0093,0.01602 -0.16528,0.134107 -0.389606,0.263622 -0.205474,0.11863 -0.384931,0.207621 -0.418126,0.207365 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 24.251904,15.256782 c -0.0017,-0.002 -0.0039,-8.39e-4 -0.0046,-0.002 l -5.222008,-9.0448416 0.812698,-0.469206 5.222037,9.0448126 c 0.0093,0.01596 -0.165131,0.134044 -0.389591,0.263648 -0.205465,0.118625 -0.396095,0.232265 -0.418139,0.207383 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4", style: {
  fill: "#652bd5",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 19.029862,6.2119394 c 0,0 -0.0047,-1.65e-4 -0.0046,-0.002 l -0.67033,-1.160973 c -0.0091,-0.01638 0.165135,-0.134058 0.389591,-0.263648 0.224438,-0.129583 0.413874,-0.22163 0.423134,-0.205591 l 0.670303,1.16104 c 0.0091,0.01635 -0.16531,0.134113 -0.389611,0.263613 -0.205447,0.118615 -0.420334,0.24626 -0.418138,0.207384 z", "sodipodi:nodetypes": "cccsccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-48-5-5", style: {
  fill: "#652bd5",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 16.022341,15.95224 c -0.0014,-0.0023 -0.0016,-0.004 -0.001,-0.0052 l 0.670294,-1.161024 0.812739,0.469221 -0.670337,1.16102 c -0.0093,0.01598 -0.198774,-0.07608 -0.423103,-0.205601 -0.205477,-0.118632 -0.372272,-0.229547 -0.388644,-0.258422 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-1-7-3", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 16.692825,14.790942 c 8.82e-4,-0.0025 -0.0016,-0.004 -10e-4,-0.0052 l 5.22206,-9.0448156 0.812697,0.469212 -5.22202,9.0448316 c -0.0092,0.01603 -0.198656,-0.07599 -0.423123,-0.205579 -0.205464,-0.118626 -0.399181,-0.226908 -0.388671,-0.258428 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-0-4-8", style: {
  fill: "#652bd5",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.1598,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 21.914868,5.7460964 c 0,0 -0.0019,-0.004 -7.68e-4,-0.0053 l 0.670299,-1.161028 c 0.0097,-0.01608 0.19867,0.07599 0.423125,0.205575 0.224435,0.129573 0.398872,0.247607 0.38961,0.263644 l -0.670337,1.161021 c -0.0097,0.01608 -0.198797,-0.07611 -0.423099,-0.205608 -0.205443,-0.118618 -0.423431,-0.240904 -0.388665,-0.258427 z", "sodipodi:nodetypes": "cccsccscc" }))));
export default SvgBipod;
