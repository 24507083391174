import * as React from "react";
const SvgArrow = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "arrow.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 12.992789, "inkscape:cy": 10.88357, "inkscape:window-width": 1920, "inkscape:window-height": 1043, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("g", { id: "g2", transform: "matrix(0.04258049,0,0,0.04258049,-31.908525,20.856843)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 861.4869,-432.2817 a 7,0.5 0 0 0 -0.0507,0.0601 l -3.7e-4,20.00003 14.00042,1.4e-4 -3.2e-4,-20.00028 a 7,0.5 0 0 0 -6.99997,-0.49967 7,0.5 0 0 0 -6.9491,0.43967 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 861.48699,-412.28189 a 7,0.5 0 0 0 -0.0511,0.0603 l 3.7e-4,229.99989 13.99973,-1.2e-4 3.2e-4,-229.99963 a 7,0.5 0 0 0 -7.00019,-0.50013 7,0.5 0 0 0 -6.94911,0.43966 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 861.48689,-182.28178 a 7,0.5 0 0 0 -0.0507,0.0601 l -3.7e-4,20.00003 a 7,0.5 0 0 0 7.00018,0.50013 7,0.5 0 0 0 7.00023,-0.5 l -3.1e-4,-20.00028 a 7,0.5 0 0 0 -6.99997,-0.49966 7,0.5 0 0 0 -6.94911,0.43967 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect101", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1036.6671,-263.93318 -8.999,10.72439 15.3209,12.85616 a 0.5,7 40 0 0 4.8822,-5.0409 0.5,7 40 0 0 4.1166,-5.68394 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect100", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 860.47672,-411.77432 -8.99899,10.72439 176.19037,147.84114 a 0.5,7 40 0 0 4.8825,-5.04045 0.5,7 40 0 0 4.1165,-5.68394 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect102", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 845.07692,-424.62959 a 0.5,7 40 0 0 -4.8036,5.04071 0.5,7 40 0 0 -4.11627,5.68324 l 15.32068,12.85571 a 0.5,7 40 0 0 4.88247,-5.04045 0.5,7 40 0 0 4.11652,-5.68394 l -15.32074,-12.85563 a 0.5,7 40 0 0 -0.079,2.9e-4 z" }), /* @__PURE__ */ React.createElement("rect", { style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, id: "rect106", width: 14, height: 120, x: 535.63464, y: 690.50671, transform: "matrix(0.76604444,0.64278761,0.64278761,-0.76604444,0,0)" }), /* @__PURE__ */ React.createElement("path", { id: "rect107", style: {
  fill: "#ffd633",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 854.286,-184.71876 a 7,1 40 0 0 -0.1165,0.0595 l -12.85617,15.32089 a 7,1 40 0 0 4.71967,5.26577 7,1 40 0 0 6.00514,3.73295 l 12.85571,-15.32068 a 7,1 40 0 0 -4.71947,-5.2653 7,1 40 0 0 -5.88836,-3.79311 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect108", style: {
  fill: "#ffd633",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 944.27574,-291.96513 a 7,1 40 0 0 -0.11646,0.0594 l -12.85573,15.3207 10.72456,8.99941 12.85594,-15.32134 a 7,1 40 0 0 -4.71942,-5.26535 7,1 40 0 0 -5.88886,-3.79284 z" }), /* @__PURE__ */ React.createElement("rect", { style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, id: "rect9-3-4-0-9", width: 14, height: 120, x: -271.13458, y: -1039.2709, transform: "rotate(90)" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-6", style: {
  fill: "#ffd633",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 1039.1503,-271.08375 a 1,7 0 0 0 -0.8793,6.9489 1,7 0 0 0 0.9994,7.00001 l 2e-4,4.7e-4 a 1,7 0 0 0 5e-4,-2.2e-4 l 20,3.7e-4 a 1,7 0 0 0 0.9997,-6.99995 1,7 0 0 0 -1,-7.00025 l -19.9971,-6e-5 a 1,7 0 0 0 0,-3.1e-4 1,7 0 0 0 -0.1204,0.051 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-2", style: {
  fill: "#ffd633",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 899.15024,-271.08357 a 1,7 0 0 0 -0.87927,6.9489 1,7 0 0 0 1.00006,7.00026 l 19.99981,-10e-5 3.5e-4,-13.99995 -20.00049,-1.5e-4 a 1,7 0 0 0 -0.12046,0.051 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-6-93-1-9", style: {
  fill: "#ff5733",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 971.90592,-172.98709 8.45251,18.12646 a 1.5,7 65 0 0 6.97802,-1.59923 1.5,7 65 0 0 5.71047,-4.31751 l -8.45251,-18.12646 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 925.41803,-272.6808 46.48801,99.69387 a 1.5,7 65 0 0 6.97823,-1.59875 1.5,7 65 0 0 5.71024,-4.31798 l -46.48801,-99.69387 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-6-93-03", style: {
  fill: "#ff5733",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 922.67598,-295.12476 a 1.5,7 65 0 0 -5.71024,4.31798 l 8.45229,18.12598 a 1.5,7 65 0 0 6.97823,-1.59875 1.5,7 65 0 0 5.71024,-4.31798 l -8.45229,-18.12598 a 1.5,7 65 0 0 -6.97823,1.59875 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-6-93-0-1", style: {
  fill: "#ff5733",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 959.21743,-167.07035 8.45251,18.12646 a 1.5,7 65 0 0 6.97802,-1.59923 1.5,7 65 0 0 5.71047,-4.31751 l -8.45251,-18.12646 z" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path99-5-6", cx: 960.95361, cy: 123.96602, rx: 7, ry: 1.5, transform: "rotate(-25)" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-4-0", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 912.72955,-266.76407 46.48802,99.69387 4.6e-4,-2.2e-4 a 1.5,7 65 0 0 6.97755,-1.599 1.5,7 65 0 0 5.71046,-4.31751 l -46.48801,-99.69387 z" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-6-8-3", style: {
  fill: "#ff5733",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3
}, d: "m 909.98751,-289.20803 a 1.5,7 65 0 0 -5.71024,4.31798 l 8.45228,18.12598 a 1.5,7 65 0 0 6.97824,-1.59875 1.5,7 65 0 0 5.71024,-4.31798 l -8.45229,-18.12598 a 1.5,7 65 0 0 -6.97823,1.59875 z" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path99-9", cx: 946.95343, cy: 123.96574, rx: 7, ry: 1.5, transform: "rotate(-25)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path109-7", cx: 868.43604, cy: -162.22156, rx: 7, ry: 0.5 }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path109-82-0", cx: -861.54126, cy: 374.48004, rx: 7, ry: 0.5, transform: "matrix(-0.64278761,0.76604444,0.76604444,0.64278761,0,0)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path109-76-2", cx: 542.63489, cy: 670.50671, rx: 7, ry: 1, transform: "matrix(0.76604444,0.64278761,0.64278761,-0.76604444,0,0)" }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  fill: "#000000",
  fillOpacity: 0.3,
  stroke: "#000000",
  strokeWidth: 0.799999,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none",
  strokeOpacity: 1
}, id: "path109-1", cx: -264.13434, cy: -1059.2709, rx: 7, ry: 1, transform: "rotate(90)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 868.13849,-395.75012 c 9.22208,1.2587 13.99424,8.82171 18.40532,14.45146", id: "path111" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 897.87097,-368.78461 c 12.01883,6.36725 17.67771,14.50007 27.97804,22.34102", id: "path112" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 938.53748,-341.11808 c 8.28688,6.92441 17.57748,13.04089 24.23154,20.93436", id: "path113" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 969.73754,-308.87381 c 1.30463,1.13715 1.73286,2.99632 2.59929,4.49447", id: "path114" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 988.25358,-297.42193 c 1.20637,6.9846 11.93582,11.80616 13.39502,12.71979", id: "path115" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 966.62151,-265.2192 c 16.80732,-0.95152 33.72959,-1.85015 50.46199,-1.80282", id: "path116" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 1001.7188,-260.45899 c 9.4502,-2.62095 19.167,-2.37524 28.5984,-1.49187", id: "path117" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 954.94913,-261.2249 c 1.34271,-0.44629 3.77179,-0.26395 4.53893,-0.28822", id: "path118" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 910.62145,-243.84523 c -3.63019,6.56707 -7.81291,12.64773 -12.52278,17.72257", id: "path119" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 880.94941,-208.86548 c 3.11263,-5.18431 8.14067,-7.63617 10.15415,-13.06955", id: "path120" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 865.33301,-187.51798 c 6.55518,-6.3253 13.53262,-12.7311 18.9775,-19.19503", id: "path121" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 919.27086,-257.13458 c 8.16703,5.70904 13.63446,17.73514 19.26662,25.00282", id: "path122" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 937.09846,-225.7088 c 4.41243,6.27926 10.72007,14.79289 13.56294,18.91056", id: "path123" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 943.88023,-207.74706 c 6.60141,7.71166 10.89777,17.43756 15.61201,25.92536", id: "path124" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 938.53748,-267.02202 c 2.41416,5.79415 4.0831,12.48578 5.34275,18.20995", id: "path125" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 944.53561,-244.13095 c 6.16003,3.5342 7.49951,9.8536 10.42037,15.45191", id: "path126" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 959.60789,-213.34467 c 0.32633,3.0844 2.11812,5.81721 3.26226,8.33888", id: "path127" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 866.73401,-375.49007 c 1.24057,4.75271 1.22078,11.11768 1.38657,15.06019", id: "path128" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 868.25274,-352.77702 c 2.14294,15.63036 -0.71993,34.26404 0.18336,48.46391", id: "path129" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 868.4361,-290.10856 c 2.47975,9.15136 -0.0823,17.81054 -1.8301,25.97405", id: "path130" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 866.47662,-243.90923 c 0.60317,4.87395 -0.53734,11.72144 -1.23547,15.29139", id: "path131" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 950.6614,-323.54006 c 6.73304,2.92716 12.5299,9.68055 19.07614,14.66625", id: "path132" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 974.09044,-188.3549 c 0.10178,1.04129 0.52697,2.11325 0.76832,3.08119", id: "path133" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#000000",
  strokeWidth: "0.264583px",
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeOpacity: 1
}, d: "m 964.79017,-206.72308 c 3.65102,5.85661 8.88823,11.19206 11.28311,17.50939", id: "path134" }))));
export default SvgArrow;
