import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import FaqQuestion from "./buildingBlocks/FaqQuestion";
import NavigationBar from "./NavigationBar";
import { questions } from "../data/questions";

type FaqProps = {
  parameterObject: {
    isLightMode: boolean;
    toggleLightmode: () => void;
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    floorColor: string;
    setFloorColor: (color: string) => void;
    isGrassTexture: boolean;
    toggleFloorTexture: () => void;
    exportLashings: boolean;
    toggleExportLashings: () => void;
  };
};

export const Faq: React.FC<FaqProps> = ({ parameterObject }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "2rem",
        minHeight: "100vh",
        overflow: "auto",
        paddingBottom: "2rem",
      }}
    >
      <NavigationBar parameterObject={parameterObject} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem 0",
          margin: "0 0 2rem 0",
          minHeight: "20rem",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            mb: 2,
            color: "primary.contrastText",
          }}
        >
          Frequently Asked Questions
        </Typography>

        <Stack
          spacing={0}
          sx={{
            width: { xs: "90%", sm: "80%", md: "60%", lg: "50%" },
            maxHeight: "70vh",
            overflowY: "auto",
            margin: "0 0 2rem 0",
            padding: "0 0 2rem 0",
          }}
        >
          {questions.map((question, index) => (
            <FaqQuestion
              key={index}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Faq;
