import * as React from "react";
const SvgLenghttool = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 64, viewBox: "0 0 16.933333 16.933333", id: "svg26255", "inkscape:version": "1.3.2 (1:1.3.2+202311252150+091e20ef0f)", "sodipodi:docname": "lenghttool.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview26257", pagecolor: "#ffffff", bordercolor: "#32ff19", borderopacity: 1, "inkscape:showpageshadow": 0, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#414141", "inkscape:document-units": "mm", showgrid: "false", "inkscape:zoom": 5.9263643, "inkscape:cx": 13.245895, "inkscape:cy": 10.967939, "inkscape:window-width": 2560, "inkscape:window-height": 1376, "inkscape:window-x": 0, "inkscape:window-y": 0, "inkscape:window-maximized": 1, "inkscape:current-layer": "layer1" }), /* @__PURE__ */ React.createElement("defs", { id: "defs26252" }, /* @__PURE__ */ React.createElement("marker", { style: {
  overflow: "visible"
}, id: "DartArrow-6-2", refX: 0, refY: 0, orient: "auto-start-reverse", "inkscape:stockid": "Dart arrow", markerWidth: 1, markerHeight: 1, viewBox: "0 0 1 1", "inkscape:isstock": "true", "inkscape:collect": "always", preserveAspectRatio: "xMidYMid" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "context-stroke",
  fillRule: "evenodd",
  stroke: "none"
}, d: "M 0,0 5,-5 -12.5,0 5,5 Z", transform: "scale(-0.5)", id: "path6-3-8" })), /* @__PURE__ */ React.createElement("marker", { style: {
  overflow: "visible"
}, id: "DartArrow-8", refX: 0, refY: 0, orient: "auto-start-reverse", "inkscape:stockid": "Dart arrow", markerWidth: 1, markerHeight: 1, viewBox: "0 0 1 1", "inkscape:isstock": "true", "inkscape:collect": "always", preserveAspectRatio: "xMidYMid" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "context-stroke",
  fillRule: "evenodd",
  stroke: "none"
}, d: "M 0,0 5,-5 -12.5,0 5,5 Z", transform: "scale(-0.5)", id: "path6-1" }))), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1" }, /* @__PURE__ */ React.createElement("path", { stroke: "currentcolor", style: {
  fill: "none",
  strokeWidth: 0.4,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeDasharray: "none",
  strokeOpacity: 1,
  markerStart: "url(#DartArrow-6-2)",
  markerEnd: "url(#DartArrow-8)"
}, d: "M 9.1839959,5.0208177 C 11.57141,9.0528007 11.57141,9.0528007 11.57141,9.0528007", id: "path1-2-9" }), /* @__PURE__ */ React.createElement("g", { id: "g16", style: {
  opacity: 0.5
}, transform: "rotate(-30,16.928297,102.24761)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-3", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.46584,23.03059 c -0.0026,-0.0015 -0.0039,-0.0031 -0.0039,-0.0046 l -2.3e-5,-1.539788 1.077882,-1.5e-5 -2.2e-5,1.539811 c -3e-6,0.02125 -0.241409,0.03847 -0.538927,0.03847 -0.272512,0 -0.502117,-0.01454 -0.534984,-0.03386 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-3", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.465848,21.490817 c -0.0026,-0.0015 -0.0039,-0.0031 -0.0039,-0.0046 l 3.1e-5,-9.937686 1.077829,7e-6 2.3e-5,9.937671 c 8.2e-5,0.02126 -0.241239,0.0385 -0.538934,0.0385 -0.272496,0 -0.502132,-0.01453 -0.535015,-0.03385 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-8", style: {
  fill: "#337aff",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.46584,11.553116 c 0,0 -0.0039,-0.0031 -0.0039,-0.0046 l -2.3e-5,-1.539796 c -5.9e-5,-0.02126 0.241247,-0.0385 0.538933,-0.0385 0.29765,1e-6 0.53895,0.01723 0.53895,0.03849 l -2.3e-5,1.539812 c -3e-6,0.02125 -0.241455,0.03846 -0.538927,0.03846 -0.272472,10e-7 -0.559527,0.0035 -0.535006,-0.03384 z", "sodipodi:nodetypes": "cccsccscc" })), /* @__PURE__ */ React.createElement("g", { id: "g17", transform: "rotate(-30,16.928297,102.24761)" }, /* @__PURE__ */ React.createElement("path", { id: "rect10-7-04-4-6-7-3-0-4", style: {
  fill: "#ff3348",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.46584,19.855588 c -0.0026,-0.0015 -0.0039,-0.0031 -0.0039,-0.0046 l -2.3e-5,-1.539788 1.077882,-1.5e-5 -2.2e-5,1.539811 c -3e-6,0.02125 -0.241409,0.03847 -0.538927,0.03847 -0.272512,0 -0.502117,-0.01454 -0.534984,-0.03386 z", "sodipodi:nodetypes": "cccccscc" }), /* @__PURE__ */ React.createElement("path", { id: "rect9-3-28-4-3-7-1-4-3-5-0", style: {
  fill: "#a05a2c",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.465848,18.315817 c -0.0019,-0.0023 -0.0039,-0.0031 -0.0039,-0.0046 l 3.1e-5,-3.587686 1.077829,7e-6 2.3e-5,3.587671 c 8.2e-5,0.02126 -0.241239,0.0385 -0.538934,0.0385 -0.272496,0 -0.511015,-0.0042 -0.535015,-0.03385 z", "sodipodi:nodetypes": "sccccsss" }), /* @__PURE__ */ React.createElement("path", { id: "rect10-7-0-8-5-5-8-6-0", style: {
  fill: "#ff3348",
  fillOpacity: 1,
  stroke: "#000000",
  strokeWidth: 0.18354,
  strokeMiterlimit: 3.3,
  strokeDasharray: "none"
}, d: "m 55.46584,14.728118 c 0,0 -0.0039,-0.0031 -0.0039,-0.0046 l -2.3e-5,-1.539796 c -5.9e-5,-0.02126 0.241247,-0.0385 0.538933,-0.0385 0.29765,10e-7 0.53895,0.01723 0.53895,0.03849 l -2.3e-5,1.539812 c -3e-6,0.02125 -0.241455,0.03846 -0.538927,0.03846 -0.272472,10e-7 -0.559527,0.0035 -0.535006,-0.03384 z", "sodipodi:nodetypes": "cccsccscc" }))));
export default SvgLenghttool;
